import * as React from "react"

import { rhythm, scale } from "../utils/typography"
import Nav from "./nav"
import { graphql, useStaticQuery } from "gatsby"
import { CSSProperties, ReactNode, ReactPropTypes } from "react"
import { SocialNode } from "../queries"

interface LayoutProps {
  location: Location
  title: string
  children?: ReactNode
}

// class Layout extends React.Component<LayoutProps, {}> {
// render() {
const Layout = (props: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  const { children } = props

  const { author }: { author: string } = data.site.siteMetadata

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: "600px",
        padding: `${rhythm(1)} ${rhythm(1)} ${rhythm(1.5)}`,
      }}
    >
      <Nav />
      <main>{children}</main>
      <footer className={"flex justify-space-between mt-10"}></footer>
    </div>
  )
}

export default Layout
